import {
    Auth,
    API,
    graphqlOperation
} from 'aws-amplify';
import {
    getProduct,
    listProducts
} from '@/graphql/queries'
import axios from "axios";
import {
    createProduct
} from '@/graphql/mutations'

const state = {
    products: [],
    product: "",
    cropValues: null,
    fieldValues: null,
    archivedProducts: []
}

const getters = {
    allTraits(state) {
        return [...new Set(state.products.map(item => item.pfs_trait))]
    },
    cornProducts(state) {
        let products = state.products.filter(item => item._sgs_seedtypeid_value === "b5023b0c-db69-df11-b6f5-a4badb3d68f3")
        if (state.cropValues && state.products.length) {
            let [low, high] = state.cropValues.cornMaturity
            return products.filter(item => item.pfs_maturity >= low && item.pfs_maturity <= high)
        }
        else {
            return products
        }
    },
    cornTraits(state, getters) {
        let traits = [...new Set(getters.cornProducts.map(item => item.pfs_trait))]
        traits = traits.filter(function( element ) {
            return element !== null;
        });
        
        let order = { "VT2PRO/ASR RIB": 1, "VT2PRO RIB": 2, "RR2": 3, "CONV": 4, "Smartstax RIB": 5, "TRE/ASR RIB": 6, "Artesian GTA": 7,  "DGVT2PRO RIB": 8,  "3110": 9,  "3120 E-Z": 10, default: Number.MAX_VALUE };

        traits.sort((a, b) =>
            (order[a] || order.default) - (order[b] || order.default) ||
            a > b || -(a < b)
        );
        return traits; 
    },
    soybeanProducts(state) {
        let products = state.products.filter(item => item._sgs_seedtypeid_value === "0acf3a12-db69-df11-b6f5-a4badb3d68f3")
        if (state.cropValues && state.products.length) {
            let [low, high] = state.cropValues.soybeanMaturity
            return products.filter(item => item.pfs_maturity >= low && item.pfs_maturity <= high)
        }
        else {
            return products
        }
    },
    archivedSoybeanProducts(state) {
        let products = state.archivedProducts.filter(item => item._sgs_seedtypeid_value === "0acf3a12-db69-df11-b6f5-a4badb3d68f3")
        if (state.cropValues && state.products.length) {
            let [low, high] = state.cropValues.soybeanMaturity
            return products.filter(item => item.pfs_maturity >= low && item.pfs_maturity <= high)
        }
        else {
            return products
        }
    },
    peaProducts() {
        let products = [
            "EP6816",
            "EP8272"
        ];
        return products
    },
    archivedCornProducts(state) {
        let products = state.archivedProducts.filter(item => item._sgs_seedtypeid_value === "b5023b0c-db69-df11-b6f5-a4badb3d68f3")
        if (state.cropValues && state.products.length) {
            let [low, high] = state.cropValues.cornMaturity
            return products.filter(item => item.pfs_maturity >= low && item.pfs_maturity <= high)
        }
        else {
            return products
        }
    },
    soybeanTraits(state, getters) {
        return [...new Set(getters.soybeanProducts.map(item => item.pfs_trait))]
    },
    brands(state) {
        // return [...new Set(state.products.map(item => item.brand))]
        return ["Peterson Farms Seed", "Dekalb", "Pioneer", "Croplan", "Stine", "Asgrow", "Other"]
    }
}

const actions = {
    async getProducts({
        commit,
        dispatch,
    }) {
        try {
            const {
                data: {
                    listProducts: {
                        items
                    }
                }
            } = await API.graphql(graphqlOperation(listProducts, {
                limit: 1000
            }))
            commit("setProducts", items)
        } catch (error) {
            console.log(error)
        }
    },
    async getCRMProducts({ rootState, commit, dispatch }) {
        try {
            let { data } = await axios.get("https://pfseed.crm.dynamics.com/api/data/v9.2/products?$filter=(statecode eq 0 and pfs_showonwebportalorders eq 229170000 and pfs_showonfieldplanner eq 229170000 and Microsoft.Dynamics.CRM.In(PropertyName='sgs_seedtypeidname',PropertyValues=['CN','SB']))", {
                headers: {
                    Authorization: rootState.user.token
                }
            })
            commit("setProducts", data.value)
            return data.value
        } catch (error) {
            if (error.message === "Request failed with status code 401") {
                await dispatch("user/getClientToken", null, {
                    root: true
                })
            }
            else {
                console.log(error)
            }
        }
    },
    async getArchivedCRMProducts({ rootState, commit, dispatch }) {
        try {
            let { data } = await axios.get("https://pfseed.crm.dynamics.com/api/data/v9.2/products?$filter=(statecode eq 0 and pfs_showonwebportalorders eq 229170001 and pfs_showonfieldplanner eq 229170000 and Microsoft.Dynamics.CRM.In(PropertyName='sgs_seedtypeidname',PropertyValues=['CN','SB']))", {
                headers: {
                    Authorization: rootState.user.token
                }
            })
            commit("setArchivedProducts", data.value)
            return data.value
        } catch (error) {
            if (error.message === "Request failed with status code 401") {
                await dispatch("user/getClientToken", null, {
                    root: true
                })
            }
            else {
                console.log(error)
            }
        }
    },
    async getProduct({
        commit
    }, id) {
        try {
            const {
                data
            } = await API.graphql(graphqlOperation(getProduct, {
                id
            }))
            commit("setCustomer", data.getProduct)
        } catch (error) {
            console.log(error)
        }
    },
    // async createProduct({
    //     commit,
    //     dispatch,
    // }, customer) {
    //     try {
    //         const { data } = await API.graphql(graphqlOperation(createCustomer, {
    //             input: {
    //                 first: customer.first,
    //                 last: customer.last,
    //                 farmName: customer.farmName,
    //                 email: customer.email,
    //                 address: customer.address,
    //                 city: customer.city,
    //                 state: customer.state,
    //                 zip: customer.zip,
    //             }
    //         }))
    //         commit("addCustomer", data.createCustomer)
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }
}

const mutations = {
    setProducts(state, products) {
        state.products = products
    },
    setArchivedProducts(state, products) {
        state.archivedProducts = products
    },
    setProduct(state, product) {
        state.product = product
    },
    setCropValues(state, cropValues) {
        state.cropValues = cropValues
    },
    setFieldValues(state, fieldValues) {
        state.fieldValues = fieldValues
    },
    addProduct(state, product) {
        state.products.push(product)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}