import {
    Auth,
    API,
    graphqlOperation
} from 'aws-amplify';
import {
    getToken,
    getUser
} from '@/graphql/queries'
import router from '@/router'

const state = {
    user: "",
    token: ""
}

const getters = {
    isAuthenticated(state) {
        return !!state.user
    },
    isAdmin(state) {
        return state.user.type === "admin"
    },
    isRep(state) {
        return state.user.type === "rep"
    }
}

const actions = {
    async getClientToken({ commit }) {
        try {
            const {
                data
            } = await API.graphql(graphqlOperation(getToken))
            let { success, token, error } = data.getToken
            if (success) {
                commit("setToken", token)
            }
        } catch (error) {
            console.log(error)
            return false
        }
    },
    async getUserData({ commit }, id) {
        try {
            const {
                data
            } = await API.graphql(graphqlOperation(getUser, {
                id
            }))
           return data.getUser
        } catch (error) {
            console.log(error)
            return false
        }
    },
    async getCustomers({ }) {
        
    },
    async signOut({ commit }) {
        try {
            await Auth.signOut()
            commit("emptyUser")
            commit("customer/emptyCustomers", null, { root: true })
            router.push("/login")
        } catch (error) {
            console.log('error signing out: ', error)
        }
    },
    async checkUser({
        commit,
        dispatch
    }) {
        try {
            let user = await Auth.currentAuthenticatedUser()
            let userData = await dispatch("getUserData", user.username)
            user.type = userData.type
            user.first = userData.first
            user.last = userData.last
            user.dealerId = userData.dealerId
            await dispatch("getClientToken")
            if (user.dealerId) {
                user.dealerInfo = await dispatch("dealer/getCRMDealer", user.dealerId, { root: true })
            }
            commit("setUser", user)
            return true
        } catch (error) {
            return false
        }
    },
}

const mutations = {
    setUser(state, user) {
        state.user = user
    },
    setToken(state, token) {
        state.token = token
    },
    emptyUser(state) {
        state.user = ""
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}