import axios from 'axios'
import oauth from 'axios-oauth-client'

export const $farmMarket = axios.create({
    headers: {
        "API-Key": "97a8f1ca960c11eb994e42010a040007"
    }
    // baseURL: "https://api.farmmarketid.com/api"
});

export const $crm = oauth.client(axios.create(), {
    // url: 'https://login.microsoftonline.com/3c6cc189-368b-413b-ade6-26efa0a3c282/oauth2/v2.0/token',
    url: '/crm',
    grant_type: 'client_credentials',
    client_id: 'f30acce6-af14-4f3f-addf-65be0836985d',
    client_secret: '5wI8Q~O-qHjiZUHEV1CMZ4lzK~W-gJxFJl7e~a6f',
    scope: 'https://pfseed.crm.dynamics.com/.default'
});