import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
const vuetify = new Vuetify({
    theme: {
      themes: {
        light: {
          primary: '#015a9c',
          secondary: '#048752'
        },
      },
    },
})

Vue.use(Vuetify);

export default vuetify;
