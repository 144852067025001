/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      email
      first
      last
      type
      phone
      dealerId
      createdAt
      updatedAt
      customers {
        items {
          id
          first
          last
          crmName
          farmName
          email
          address
          city
          state
          zip
          phone
          growerId
          lat
          lon
          prospect
          dealerId
          dealerName
          createdAt
          updatedAt
        }
        nextToken
      }
      plans {
        items {
          id
          createdAt
          type
          name
          year
          defaultSettings
          cropValues
          dealer
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        first
        last
        type
        phone
        dealerId
        createdAt
        updatedAt
        customers {
          nextToken
        }
        plans {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getCustomer = /* GraphQL */ `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      first
      last
      crmName
      farmName
      email
      address
      city
      state
      zip
      phone
      growerId
      lat
      lon
      prospect
      dealerId
      dealerName
      createdAt
      updatedAt
      user {
        id
        email
        first
        last
        type
        phone
        dealerId
        createdAt
        updatedAt
        customers {
          nextToken
        }
        plans {
          nextToken
        }
      }
      dealers {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      plans {
        items {
          id
          createdAt
          type
          name
          year
          defaultSettings
          cropValues
          dealer
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listCustomers = /* GraphQL */ `
  query ListCustomers(
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        first
        last
        crmName
        farmName
        email
        address
        city
        state
        zip
        phone
        growerId
        lat
        lon
        prospect
        dealerId
        dealerName
        createdAt
        updatedAt
        user {
          id
          email
          first
          last
          type
          phone
          dealerId
          createdAt
          updatedAt
        }
        dealers {
          nextToken
        }
        plans {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getDealer = /* GraphQL */ `
  query GetDealer($id: ID!) {
    getDealer(id: $id) {
      id
      name
      code
      createdAt
      updatedAt
      customers {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listDealers = /* GraphQL */ `
  query ListDealers(
    $filter: ModelDealerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDealers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        code
        createdAt
        updatedAt
        customers {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getDealerCustomer = /* GraphQL */ `
  query GetDealerCustomer($id: ID!) {
    getDealerCustomer(id: $id) {
      id
      createdAt
      updatedAt
      customer {
        id
        first
        last
        crmName
        farmName
        email
        address
        city
        state
        zip
        phone
        growerId
        lat
        lon
        prospect
        dealerId
        dealerName
        createdAt
        updatedAt
        user {
          id
          email
          first
          last
          type
          phone
          dealerId
          createdAt
          updatedAt
        }
        dealers {
          nextToken
        }
        plans {
          nextToken
        }
      }
      dealer {
        id
        name
        code
        createdAt
        updatedAt
        customers {
          nextToken
        }
      }
    }
  }
`;
export const listDealerCustomers = /* GraphQL */ `
  query ListDealerCustomers(
    $filter: ModelDealerCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDealerCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        customer {
          id
          first
          last
          crmName
          farmName
          email
          address
          city
          state
          zip
          phone
          growerId
          lat
          lon
          prospect
          dealerId
          dealerName
          createdAt
          updatedAt
        }
        dealer {
          id
          name
          code
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getPlan = /* GraphQL */ `
  query GetPlan($id: ID!) {
    getPlan(id: $id) {
      id
      createdAt
      type
      name
      year
      defaultSettings
      cropValues
      dealer
      products {
        product
        brand
        crop
        trait
        acres
        maturity
        calcUnits
        orderUnits
      }
      updatedAt
      owner {
        id
        email
        first
        last
        type
        phone
        dealerId
        createdAt
        updatedAt
        customers {
          nextToken
        }
        plans {
          nextToken
        }
      }
      customer {
        id
        first
        last
        crmName
        farmName
        email
        address
        city
        state
        zip
        phone
        growerId
        lat
        lon
        prospect
        dealerId
        dealerName
        createdAt
        updatedAt
        user {
          id
          email
          first
          last
          type
          phone
          dealerId
          createdAt
          updatedAt
        }
        dealers {
          nextToken
        }
        plans {
          nextToken
        }
      }
      fields {
        items {
          id
          fieldId
          name
          number
          done
          acres
          tillage
          drainage
          irrigation
          yieldEnvironment
          idc
          gossWilt
          greensnap
          whiteMold
          phytophthora
          cyst
          trait
          product
          crop
          brand
          population
          rate
          variableRate
          zones
          rateRange
          rateSubmitted
          avgRate
          geoJson
          rowSpacing
          ph
          yieldTarget
          protein
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listPlans = /* GraphQL */ `
  query ListPlans(
    $filter: ModelPlanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPlans(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        type
        name
        year
        defaultSettings
        cropValues
        dealer
        products {
          product
          brand
          crop
          trait
          acres
          maturity
          calcUnits
          orderUnits
        }
        updatedAt
        owner {
          id
          email
          first
          last
          type
          phone
          dealerId
          createdAt
          updatedAt
        }
        customer {
          id
          first
          last
          crmName
          farmName
          email
          address
          city
          state
          zip
          phone
          growerId
          lat
          lon
          prospect
          dealerId
          dealerName
          createdAt
          updatedAt
        }
        fields {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const plansByDate = /* GraphQL */ `
  query PlansByDate(
    $type: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPlanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    plansByDate(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        type
        name
        year
        defaultSettings
        cropValues
        dealer
        products {
          product
          brand
          crop
          trait
          acres
          maturity
          calcUnits
          orderUnits
        }
        updatedAt
        owner {
          id
          email
          first
          last
          type
          phone
          dealerId
          createdAt
          updatedAt
        }
        customer {
          id
          first
          last
          crmName
          farmName
          email
          address
          city
          state
          zip
          phone
          growerId
          lat
          lon
          prospect
          dealerId
          dealerName
          createdAt
          updatedAt
        }
        fields {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const plansByDealer = /* GraphQL */ `
  query PlansByDealer(
    $dealer: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPlanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    plansByDealer(
      dealer: $dealer
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        type
        name
        year
        defaultSettings
        cropValues
        dealer
        products {
          product
          brand
          crop
          trait
          acres
          maturity
          calcUnits
          orderUnits
        }
        updatedAt
        owner {
          id
          email
          first
          last
          type
          phone
          dealerId
          createdAt
          updatedAt
        }
        customer {
          id
          first
          last
          crmName
          farmName
          email
          address
          city
          state
          zip
          phone
          growerId
          lat
          lon
          prospect
          dealerId
          dealerName
          createdAt
          updatedAt
        }
        fields {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getField = /* GraphQL */ `
  query GetField($id: ID!) {
    getField(id: $id) {
      id
      fieldId
      name
      number
      done
      acres
      tillage
      drainage
      irrigation
      yieldEnvironment
      idc
      gossWilt
      greensnap
      whiteMold
      phytophthora
      cyst
      trait
      product
      crop
      brand
      population
      rate
      variableRate
      zones
      rateRange
      rateSubmitted
      avgRate
      geoJson
      rowSpacing
      ph
      yieldTarget
      protein
      createdAt
      updatedAt
      plan {
        id
        createdAt
        type
        name
        year
        defaultSettings
        cropValues
        dealer
        products {
          product
          brand
          crop
          trait
          acres
          maturity
          calcUnits
          orderUnits
        }
        updatedAt
        owner {
          id
          email
          first
          last
          type
          phone
          dealerId
          createdAt
          updatedAt
        }
        customer {
          id
          first
          last
          crmName
          farmName
          email
          address
          city
          state
          zip
          phone
          growerId
          lat
          lon
          prospect
          dealerId
          dealerName
          createdAt
          updatedAt
        }
        fields {
          nextToken
        }
      }
    }
  }
`;
export const listFields = /* GraphQL */ `
  query ListFields(
    $filter: ModelFieldFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFields(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        fieldId
        name
        number
        done
        acres
        tillage
        drainage
        irrigation
        yieldEnvironment
        idc
        gossWilt
        greensnap
        whiteMold
        phytophthora
        cyst
        trait
        product
        crop
        brand
        population
        rate
        variableRate
        zones
        rateRange
        rateSubmitted
        avgRate
        geoJson
        rowSpacing
        ph
        yieldTarget
        protein
        createdAt
        updatedAt
        plan {
          id
          createdAt
          type
          name
          year
          defaultSettings
          cropValues
          dealer
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      name
      trait
      maturity
      seedType
      brand
      archived
      createdAt
      updatedAt
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        trait
        maturity
        seedType
        brand
        archived
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getToken = /* GraphQL */ `
  query GetToken {
    getToken {
      success
      token
      error
    }
  }
`;
export const getGeoData = /* GraphQL */ `
  query GetGeoData($userId: String, $lat: Float, $lon: Float, $radius: Int) {
    getGeoData(userId: $userId, lat: $lat, lon: $lon, radius: $radius)
  }
`;
export const sendEmail = /* GraphQL */ `
  query SendEmail($message: String) {
    sendEmail(message: $message)
  }
`;
