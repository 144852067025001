import Vue from 'vue'
import VueRouter from 'vue-router'
import Store from '../store/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    beforeEnter: requireAuth
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/customers',
    name: 'Customers',
    component: () => import('../views/Customers.vue'),
    beforeEnter: requireAuth
  },
  {
    path: '/tutorial',
    name: 'Tutorial',
    component: () => import('../views/Tutorial.vue'),
    beforeEnter: requireAuth
  },
  {
    path: '/prospect',
    name: 'Prospect',
    component: () => import('../views/Prospect.vue'),
    beforeEnter: requireAuth
  },
  {
    path: '/dealers',
    name: 'Dealers',
    component: () => import('../views/Dealers.vue'),
    beforeEnter: requireAuth
  },
  {
    path: '/dealers/:id/customers',
    name: 'DealerCustomers',
    component: () => import('../views/Customers.vue'),
    beforeEnter: requireAuth
  },
  {
    path: '/products',
    name: 'Products',
    component: () => import('../views/Products.vue'),
    beforeEnter: requireAuth
  },
  {
    path: '/customers/:id',
    name: 'Customer',
    component: () => import('../views/Customer.vue'),
    beforeEnter: requireAuth
  },
  {
    path: '/plans',
    name: 'Plans',
    component: () => import('../views/Plans.vue'),
    beforeEnter: requireAuth
  },
  {
    path: '/customers/:id/newPlan',
    name: 'NewPlan',
    component: () => import('../components/NewPlan.vue'),
    beforeEnter: requireAuth
  },
  {
    path: '/plans/:id/edit',
    name: 'EditPlan',
    component: () => import('../views/EditPlan.vue'),
    beforeEnter: requireAuth
  },
  {
    path: '/plans/:id',
    name: 'Plan',
    component: () => import('../views/Plan.vue'),
    beforeEnter: requireAuth
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

async function requireAuth(to, from, next) {
  if (Store.getters["user/isAuthenticated"]) {
    next()
  } else {
    if (await Store.dispatch("user/checkUser")) {
      next()
    }
    else {
      next("login")
    }
  }
}

export default router
