<template>
  <v-app>
    <v-app-bar app>
      <v-toolbar-title>
        <router-link to="/" class="header-name">
          <v-img width="50px" :src="require('@/assets/logo.svg')" />
          <div class="app-title">Field Planner</div>
        </router-link>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items v-if="user">
        <!-- <v-btn text to="/">Dashboard</v-btn> -->
        <v-btn text to="/customers" class="toolbar-buttons">Customers</v-btn>
        <v-btn v-if="isAdmin" text to="/dealers" class="toolbar-buttons">Dealers</v-btn>
        <v-btn text to="/plans" class="toolbar-buttons">Plans</v-btn>
        <v-btn v-if="isAdmin" text to="/products" class="toolbar-buttons">Products</v-btn>
        <v-btn text to="/prospect" class="toolbar-buttons">Prospect</v-btn>
        <v-btn text @click="$store.dispatch('user/signOut')" class="toolbar-buttons">Sign Out</v-btn>
        <!-- <v-btn text>Logout</v-btn> -->
      </v-toolbar-items>
    </v-app-bar>
    <v-main>
      <v-container fluid>
        <router-view :key="$route.fullPath" />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
  name: 'App',
  data: () => ({
    //
  }),
  computed: {
    ...mapState("user", ["user"]),
    ...mapGetters("user", ["isAdmin"]),
  }
};
</script>
<style lang="scss">
  @import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');
  body {
    font-family: 'Oswald', sans-serif;
  }
  .app-title{
    font-size: 1.1rem;
    text-transform: uppercase;
  }
  .header-name{
    display: flex;
    text-decoration: none;
    align-items: center;
    .v-image{
      margin-right: .75rem;
    }
  }
  :root {
    --amplify-primary-color: #015a9c;
    --amplify-secondary-color: #048752;
    --amplify-primary-tint: #015a9c; 
    --amplify-primary-shade: #048752;
    --amplify-font-family: 'Oswald', sans-serif;
  }

.toolbar-buttons:hover{
  background: #015a9c;
  transition: opacity 0.4s ease-out;
  color: #fff !important;
}
  
</style>