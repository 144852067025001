import {
    Auth,
    API,
    graphqlOperation
} from 'aws-amplify';
import {
    getCustomer,
    listCustomers,
    sendEmail
} from '@/graphql/queries';
import { getCustomerDetails } from "@/graphql/custom"
import axios from "axios";
import {
    createCustomer
} from '@/graphql/mutations';

const state = {
    customers: [],
    customer: ""
}

const getters = {
}

const actions = {
    async getCRMCustomers({ rootState, commit, dispatch }, link) {
        let url = "https://pfseed.crm.dynamics.com/api/data/v9.1/accounts?$select=accountid,name,_pfs_dealerid_value,pfs_farmmarketid,telephone1,emailaddress1,address1_line1,address1_city,address1_stateorprovince,address1_postalcode,address1_latitude,address1_longitude&$orderby=name asc&$filter=name ne null and statecode eq 0 and _pfs_dealerid_value ne null&$expand=primarycontactid($select=firstname,lastname),pfs_dealerid($select=pfs_name)"
        if (link) url = link
        try {
            let { data } = await axios.get(url, {
                headers: {
                    Authorization: rootState.user.token
                }
            })
            commit("setCustomers", data.value)
            if (data['@odata.nextLink']) {
                dispatch("getCRMCustomers", data['@odata.nextLink'])
            }
            else {
                dispatch("getCustomers")
            }
        } catch (error) {
            if (error.message === "Request failed with status code 401") {
                await dispatch("user/getClientToken", null, {
                    root: true
                })
            }
            else {
                console.log(error)
            }
        }
    },
    async getDealerCustomers({ rootState, commit, dispatch }, dealerId) {
        try {
            let { data } = await axios.get(`https://pfseed.crm.dynamics.com/api/data/v9.1/accounts?$select=accountid,name,_pfs_dealerid_value,pfs_farmmarketid,telephone1,emailaddress1,address1_line1,address1_city,address1_stateorprovince,address1_postalcode,address1_latitude,address1_longitude&$orderby=name asc&$filter=_pfs_dealerid_value eq ${dealerId} and statecode eq 0 and name ne null&$expand=pfs_account_pfs_dealer_DealerAccount($select=pfs_name),primarycontactid($select=firstname,lastname),pfs_dealerid($select=pfs_name)`, {
                headers: {
                    Authorization: rootState.user.token
                }
            })
            commit("setCustomers", data.value)
            dispatch("getCustomers", dealerId)
        } catch (error) {
            if (error.message === "Request failed with status code 401") {
                await dispatch("user/getClientToken", null, {
                    root: true
                })
            }
            else {
                console.log(error)
            }
        }
    },
    async checkCustomer({ rootState, commit, dispatch }, farmMarketId) {
        try {
            let { data } = await axios.get(`https://pfseed.crm.dynamics.com/api/data/v9.1/accounts?$select=accountid,name,_pfs_dealerid_value,pfs_farmmarketid,telephone1,emailaddress1,address1_line1,address1_city,address1_stateorprovince,address1_postalcode,address1_latitude,address1_longitude&$filter=pfs_farmmarketid eq '${farmMarketId}'&$expand=pfs_dealerid($select=pfs_name)`, {
                headers: {
                    Authorization: rootState.user.token
                }
            })
            return data.value
            // commit("setCustomers", data.value)
        } catch (error) {
            if (error.message === "Request failed with status code 401") {
                await dispatch("user/getClientToken", null, {
                    root: true
                })
            }
            else {
                console.log(error)
            }
        }
    },
    async getCRMCustomer({ rootState, commit, dispatch }, customerId) {
        try {
            let { data } = await axios.get(`https://pfseed.crm.dynamics.com/api/data/v9.1/accounts(${customerId})?$expand=primarycontactid,pfs_dealerid($select=pfs_name)`, {
                headers: {
                    Authorization: rootState.user.token
                }
            })
            return data
        } catch (error) {
            if (error.message === "Request failed with status code 401") {
                await dispatch("user/getClientToken", null, {
                    root: true
                })
            }
            else {
                // console.log(error)
                return false
            }
        }
    },
    async getCustomers({
        commit,
        dispatch,
    }, dealerId) {
        let filter;
        if (dealerId) {
            filter = {
                and: [
                    {
                        prospect: {
                            eq: true
                        }
                    },
                    {
                        dealerId: {
                            eq: dealerId
                        }
                    }
                ]
                
            }
        }
        else {
            filter = {
                prospect: {
                    eq: true
                }
            }
        }

        try {
            const {
                data: {
                    listCustomers: {
                        items
                    }
                }
            } = await API.graphql(graphqlOperation(listCustomers, {
                limit: 1000,
                filter
            }))
            if (!items.length) return
            let format = items.map(item => {
                return {
                    accountid: item.id,
                    name: item.crmName,
                    emailaddress1: item.email,
                    telephone1: item.phone,
                    address1_line1: item.address,
                    address1_city: item.city,
                    address1_stateorprovince: item.state,
                    address1_postalcode: item.zip,
                    prospect: item.prospect,
                    pfs_dealerid: {
                        pfs_name: item.dealerName
                    }
                }
            })
            commit("setCustomers", format)
        } catch (error) {
            console.log(error)
        }
    },
    async sendProspectEmail({
    }, message) {
        try {
            const { data } = await API.graphql(graphqlOperation(sendEmail, {
                message
            }))
        } catch (error) {
            console.log(error)
        }
    },
    async getCustomerData({
        dispatch,
        commit,
        rootState
    }, customerId) {
        let [cust, api] = await Promise.all([dispatch("getCustomer", customerId), dispatch("getCRMCustomer", customerId)])
        if (!api && !cust) return
        if (api && cust) {
            api.plans = cust.plans.items
        }
        else if (cust) {
            cust.accountid = cust.id
            cust.name = cust.crmName
            cust.primarycontactid = {
                firstname: cust.first,
                lastname: cust.last
            }
            cust.address1_line1 = cust.address
            cust.address1_city = cust.city
            cust.address1_stateorprovince = cust.state
            cust.address1_postalcode = cust.zip
            cust.emailaddress1 = cust.email
            cust.plans = cust.plans.items
            cust.pfs_farmmarketid = cust.growerId
        }
        commit("setCustomer", api ? api : cust)
    },
    async getCustomer({
        commit
    }, id) {
        try {
            const {
                data
            } = await API.graphql(graphqlOperation(getCustomerDetails, {
                id
            }))
            if (data.getCustomer) {
                return data.getCustomer
            }
            else return false
        } catch (error) {
            console.log(error)
            return false
        }
    },
    async createCustomer({
        commit,
        dispatch,
    }, customer) {
        try {
            const { data } = await API.graphql(graphqlOperation(createCustomer, {
                input: {
                    id: customer.id,
                    first: customer.first,
                    last: customer.last,
                    farmName: customer.farmName,
                    crmName: customer.crmName,
                    email: customer.email,
                    address: customer.address,
                    city: customer.city,
                    state: customer.state,
                    zip: customer.zip,
                    growerId: customer.growerId
                }
            }))
            // commit("addCustomer", data.createCustomer)
            return data.createCustomer
        } catch (error) {
            console.log(error)
        }
    },
    async createProspect({
        dispatch,
    }, customer) {
        try {
            const { data } = await API.graphql(graphqlOperation(createCustomer, {
                input: {
                    first: customer.First_Name,
                    last: customer.Last_Name,
                    crmName: customer.crmName,
                    email: customer.Email_Address,
                    address: customer.Address_1,
                    city: customer.City,
                    state: customer.State_Code,
                    zip: customer.Zip_Code,
                    growerId: customer.Grower_Id,
                    phone: customer.Phone_Number,
                    prospect: true,
                    customerUserId: customer.userId,
                    dealerId: customer.dealerId,
                    dealerName: customer.dealerName
                }
            }))
            await dispatch("sendProspectEmail", JSON.stringify(data.createCustomer))
            return data.createCustomer
        } catch (error) {
            console.log(error)
        }
    }
}

const mutations = {
    setCustomers(state, customers) {
        state.customers.push(...customers)
    },
    emptyCustomer(state) {
        state.customer = ""
    },
    emptyCustomers(state) {
        state.customers = []
    },
    setCustomer(state, customer) {
        state.customer = customer
    },
    addCustomer(state, customer) {
        state.customers.push(customer)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}