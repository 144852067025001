import Vue from 'vue'
import {
    API,
    graphqlOperation
} from 'aws-amplify';
import {
    $farmMarket
} from "@/plugins/axios";
import {
    listPlans,
    getGeoData,
    plansByDate,
    plansByDealer,
    sendEmail
} from '@/graphql/queries'
import { getPlan } from "@/graphql/custom"

import {
    createPlan,
    createField,
    deletePlan,
    deleteField,
    updateField,
    updatePlan
} from '@/graphql/mutations'

import rewind from "geojson-rewind"
import { path } from "ramda"

const state = {
    plan: "",
    plans: [],
    fields: []
}

const getters = {
}

const actions = {
    async getPlans({
        commit,
        dispatch,
    }) {
        try {
            const {
                data
            } = await API.graphql(graphqlOperation(plansByDate, {
                limit: 1000,
                type: "Plan",
                sortDirection: "DESC"
            }))
            commit("setPlans", data.plansByDate.items)
        } catch (error) {
            console.log(error)
        }
    },
    async plansByDealer({
        commit
    }, dealer) {
        try {
            const {
                data
            } = await API.graphql(graphqlOperation(plansByDealer, {
                dealer,
                sortDirection: "DESC",
                limit: 1000
            }))
            commit("setPlans", data.plansByDealer.items)
        } catch (error) {
            console.log(error)
        }
    },
    async getPlan({
        commit,
        dispatch,
    }, id) {
        try {
            const {
                data
            } = await API.graphql(graphqlOperation(getPlan, {
                id
            }))
            commit("setPlan", data.getPlan)
            return data.getPlan
        } catch (error) {
            console.log(error)
        }
    },
    async getGrowerFields({ dispatch }, growerId) {
        let { features } = await dispatch("getGrowerGeoData", growerId)
        return features.map(item => {
            let { properties } = item
            return {
                landId: properties.fmid_land_id,
                lat: properties.lat,
                lon: properties.lon,
                acres: properties.acres,
                county: properties.county,
                crop: properties.crop,
                zip: properties.zip_cd
            }
        })
    },
    async getGrowerGeoData({
        commit,
        dispatch
    }, userId) {
        try {
            const {
                data
            } = await API.graphql(graphqlOperation(getGeoData, {
                userId
            }))
            return JSON.parse(data.getGeoData)
        } catch (error) {
            console.log(error)
        }
    },
    async getGrowerData({
        commit,
        dispatch
    }, grower) {
        try {
            const {
                data
            } = await API.graphql(graphqlOperation(getGeoData, {
                lat: grower.lat,
                lon: grower.lon,
                radius: grower.radius
            }))
            if (data.getGeoData.length) {
                return JSON.parse(data.getGeoData)
                // console.log(prospects)
                // let land = await Promise.all(prospects.map(async item => {
                //     item.fields = await dispatch("getGrowerGeoData", item.Grower_ID)
                //     return item
                // }))
                // return land.filter(item => item.fields.features)
            }
        } catch (error) {
            console.log(error)
        }
    },
    async getGeoData({
        commit,
        dispatch
    }, growerId) {
        let url = "/farmMarket"
        if (!Vue.config.devtools) {
            url = "https://api.farmmarketid.com/api"
        }
        try {
            let { data: [result] } = await $farmMarket.get(`${url}/v1/geospatial/grower/${growerId}`)
            // let final = rewind(result)
            return result
        } catch (error) {
            console.log(error)
            return error
        }
    },
    async createFinalPlan({
        dispatch,
        commit
    }, data) {
        let customer = await dispatch("customer/getCustomer", data.planCustomerId, {
            root: true
        })
        if (!customer) {
            customer = await dispatch("customer/createCustomer", {
                id: data.planCustomerId,
                growerId: data.planGrowerId,
                crmName: data.crmName
            }, {
                root: true
            })
        }
        let plan = await dispatch("createPlan", {
            planCustomerId: data.planCustomerId,
            planOwnerId: data.planOwnerId,
            year: data.year,
            name: data.name,
            defaultSettings: data.defaultSettings,
            cropValues: data.cropValues,
            dealer: data.dealerId
        })

        let newFields = await Promise.all(data.fields.map(item => {
            item.planId = plan.id
            return dispatch("createField", item)
        }))
        return plan.id
    },
    async copyPlan({
        commit,
        dispatch,
    }, data) {
        try {
            let plan = await dispatch("createPlan", {
                planCustomerId: data.planCustomerId,
                planOwnerId: data.planOwnerId,
                year: data.year,
                name: data.name,
                defaultSettings: data.defaultSettings,
                cropValues: data.cropValues,
                dealer: data.dealer
            })
            let items = await Promise.all(data.fields.items.map(item => {
                item.planId = plan.id
                return dispatch("createField", item)
            }))
            // add fields to plan
            plan.fields = {
                items
            }
            return plan
        } catch (error) {
            console.log(error)
        }
    },
    async createField({
        dispatch,
        commit
    }, field) {
        try {
            const { data } = await API.graphql(graphqlOperation(createField, {
                input: {
                    fieldPlanId: field.planId,
                    name: field.name,
                    fieldId: field.fieldId,
                    acres: field.acres,
                    done: field.done,
                    tillage: field.tillage,
                    drainage: field.drainage,
                    irrigation: field.irrigation,
                    yieldEnvironment: field.yieldEnvironment,
                    idc: field.idc,
                    gossWilt: field.gossWilt,
                    greensnap: field.greensnap,
                    whiteMold: field.whiteMold,
                    phytophthora: field.phytophthora,
                    cyst: field.cyst,
                    trait: field.trait,
                    product: field.product,
                    crop: field.crop,
                    brand: field.brand,
                    rate: field.rate,
                    variableRate: field.variableRate,
                    zones: field.zones,
                    rateRange: field.rateRange,
                    rateSubmitted: field.rateSubmitted,
                    avgRate: field.avgRate,
                    population: field.population,
                    geoJson: field.geoJson,
                    rowSpacing: field.rowSpacing,
                    ph: field.ph, 
                    yieldTarget: field.yieldTarget,
                    protein: field.protein
                }
            }))
            return data.createField
        } catch (error) {
            console.log(error)
        }
    },
    async updateField({
        dispatch,
        commit
    }, field) {
        try {
            const { data } = await API.graphql(graphqlOperation(updateField, {
                input: {
                    id: field.id,
                    fieldPlanId: field.planId,
                    name: field.name,
                    fieldId: field.fieldId,
                    done: field.done,
                    acres: field.acres,
                    tillage: field.tillage,
                    drainage: field.drainage,
                    irrigation: field.irrigation,
                    yieldEnvironment: field.yieldEnvironment,
                    idc: field.idc,
                    gossWilt: field.gossWilt,
                    greensnap: field.greensnap,
                    whiteMold: field.whiteMold,
                    phytophthora: field.phytophthora,
                    cyst: field.cyst,
                    trait: field.trait,
                    product: field.product,
                    crop: field.crop,
                    brand: field.brand,
                    rate: field.rate,
                    variableRate: field.variableRate,
                    zones: field.zones,
                    rateRange: field.rateRange,
                    rateSubmitted: field.rateSubmitted,
                    avgRate: field.avgRate,
                    population: field.population,
                    geoJson: field.geoJson,
                    rowSpacing: field.rowSpacing,
                    ph: field.ph,
                    yieldTarget: field.yieldTarget,
                    protein: field.protein
                }
            }))
            return data.updateField
            // commit("addPlan", data.createPlan)
        } catch (error) {
            console.log(error)
        }
    },
    async updatePlanProducts({}, plan) {
        try {
            const { data } = await API.graphql(graphqlOperation(updatePlan, {
                input: {
                    id: plan.id,
                    products: plan.products
                }
            }))
            return data.updatePlan
            // commit("addPlan", data.createPlan)
        } catch (error) {
            console.log(error)
        }
    },
    async createPlan({
        commit,
        dispatch,
    }, plan) {
        try {
            const { data } = await API.graphql(graphqlOperation(createPlan, {
                input: {
                    name: plan.name,
                    type: "Plan",
                    planCustomerId: plan.planCustomerId,
                    planOwnerId: plan.planOwnerId,
                    year: plan.year,
                    defaultSettings: plan.defaultSettings,
                    cropValues: plan.cropValues,
                    dealer: plan.dealer
                }
            }))
            return data.createPlan
            // commit("addPlan", data.createPlan)
        } catch (error) {
            console.log(error)
        }
    },
    async sendRateEmail({
    }, message) {
        try {
            const { data } = await API.graphql(graphqlOperation(sendEmail, {
                message
            }))
            return data.sendEmail
        } catch (error) {
            console.log(error)
        }
    },
    async removePlan({
        commit,
        dispatch,
    }, plan) {
        try {
            const { data } = await API.graphql(graphqlOperation(deletePlan, {
                input: {
                    id: plan.id
                }
            }))
            if (data.deletePlan) {
                await Promise.all(plan.fields.items.map(item => {
                    return dispatch("removeField", item.id)
                }))
                return true
            }
        } catch (error) {
            console.log(error)
            return false
        }
        
    },
    async removeField({
        commit,
        dispatch,
    }, id) {
        try {
            const { data } = await API.graphql(graphqlOperation(deleteField, {
                input: {
                    id
                }
            }))
            return data.deleteField
        } catch (error) {
            console.log(error)
        }
    }
}

const mutations = {
    addPlan(state, plan) {
        state.plans.push(plan)
    },
    removePlanId(state, planId) {
        let ind = state.plans.findIndex(item => item.id === planId)
        console.log(ind)
        state.plans.splice(ind, 1)
    },
    setPlans(state, plans) {
        state.plans = plans
    },
    setPlan(state, plan) {
        state.plan = plan
    },
    setFields(state, fields) {
        state.fields = fields
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}