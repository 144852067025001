export const getPlan = /* GraphQL */ `
  query GetPlan($id: ID!) {
    getPlan(id: $id) {
      id
      name
      year
      defaultSettings
      cropValues
      dealer
      products {
        product
        brand
        crop
        trait
        acres
        maturity
        calcUnits
        orderUnits
      }
      createdAt
      updatedAt
      owner {
        id
        email
        first
        last
        type
        phone
        dealerId
        createdAt
        updatedAt
        plans {
          nextToken
        }
      }
      customer {
        id
        first
        last
        crmName
        farmName
        email
        address
        city
        state
        zip
        growerId
        lat
        lon
        dealerId
        dealerName
        prospect
        createdAt
        updatedAt
        dealers {
          nextToken
        }
        plans {
          nextToken
        }
      }
      fields(limit: 1000) {
        items {
          id
          fieldId
          name
          number
          done
          acres
          tillage
          drainage
          irrigation
          yieldEnvironment
          idc
          gossWilt
          greensnap
          whiteMold
          phytophthora
          cyst
          trait
          product
          crop
          brand
          population
          rate
          variableRate
          zones
          rateRange
          avgRate
          rateSubmitted
          geoJson
          rowSpacing
          ph
          yieldTarget
          protein
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const getCustomerDetails = /* GraphQL */ `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      first
      last
      farmName
      crmName
      email
      address
      city
      state
      zip
      growerId
      lat
      lon
      dealerId
      dealerName
      createdAt
      updatedAt
      dealers {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      plans {
        items {
          id
          name
          year
          defaultSettings
          cropValues
          dealer
          owner {
            id
            email
          }
          fields(limit: 1000) {
            items {
              id
              fieldId
              name
              number
              done
              acres
              tillage
              drainage
              irrigation
              yieldEnvironment
              idc
              gossWilt
              greensnap
              whiteMold
              phytophthora
              cyst
              trait
              product
              crop
              brand
              population
              rate
              geoJson
              rowSpacing
              createdAt
              updatedAt
            }
          }
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;