import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import customer from './modules/customer'
import plan from './modules/plan'
import dealer from './modules/dealer'
import product from './modules/product'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    customer,
    plan,
    dealer,
    product
  }
})