/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      email
      first
      last
      type
      phone
      dealerId
      createdAt
      updatedAt
      customers {
        items {
          id
          first
          last
          crmName
          farmName
          email
          address
          city
          state
          zip
          phone
          growerId
          lat
          lon
          prospect
          dealerId
          dealerName
          createdAt
          updatedAt
        }
        nextToken
      }
      plans {
        items {
          id
          createdAt
          type
          name
          year
          defaultSettings
          cropValues
          dealer
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      email
      first
      last
      type
      phone
      dealerId
      createdAt
      updatedAt
      customers {
        items {
          id
          first
          last
          crmName
          farmName
          email
          address
          city
          state
          zip
          phone
          growerId
          lat
          lon
          prospect
          dealerId
          dealerName
          createdAt
          updatedAt
        }
        nextToken
      }
      plans {
        items {
          id
          createdAt
          type
          name
          year
          defaultSettings
          cropValues
          dealer
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      email
      first
      last
      type
      phone
      dealerId
      createdAt
      updatedAt
      customers {
        items {
          id
          first
          last
          crmName
          farmName
          email
          address
          city
          state
          zip
          phone
          growerId
          lat
          lon
          prospect
          dealerId
          dealerName
          createdAt
          updatedAt
        }
        nextToken
      }
      plans {
        items {
          id
          createdAt
          type
          name
          year
          defaultSettings
          cropValues
          dealer
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createCustomer = /* GraphQL */ `
  mutation CreateCustomer(
    $input: CreateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    createCustomer(input: $input, condition: $condition) {
      id
      first
      last
      crmName
      farmName
      email
      address
      city
      state
      zip
      phone
      growerId
      lat
      lon
      prospect
      dealerId
      dealerName
      createdAt
      updatedAt
      user {
        id
        email
        first
        last
        type
        phone
        dealerId
        createdAt
        updatedAt
        customers {
          nextToken
        }
        plans {
          nextToken
        }
      }
      dealers {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      plans {
        items {
          id
          createdAt
          type
          name
          year
          defaultSettings
          cropValues
          dealer
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer(
    $input: UpdateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    updateCustomer(input: $input, condition: $condition) {
      id
      first
      last
      crmName
      farmName
      email
      address
      city
      state
      zip
      phone
      growerId
      lat
      lon
      prospect
      dealerId
      dealerName
      createdAt
      updatedAt
      user {
        id
        email
        first
        last
        type
        phone
        dealerId
        createdAt
        updatedAt
        customers {
          nextToken
        }
        plans {
          nextToken
        }
      }
      dealers {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      plans {
        items {
          id
          createdAt
          type
          name
          year
          defaultSettings
          cropValues
          dealer
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteCustomer = /* GraphQL */ `
  mutation DeleteCustomer(
    $input: DeleteCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    deleteCustomer(input: $input, condition: $condition) {
      id
      first
      last
      crmName
      farmName
      email
      address
      city
      state
      zip
      phone
      growerId
      lat
      lon
      prospect
      dealerId
      dealerName
      createdAt
      updatedAt
      user {
        id
        email
        first
        last
        type
        phone
        dealerId
        createdAt
        updatedAt
        customers {
          nextToken
        }
        plans {
          nextToken
        }
      }
      dealers {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      plans {
        items {
          id
          createdAt
          type
          name
          year
          defaultSettings
          cropValues
          dealer
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createDealer = /* GraphQL */ `
  mutation CreateDealer(
    $input: CreateDealerInput!
    $condition: ModelDealerConditionInput
  ) {
    createDealer(input: $input, condition: $condition) {
      id
      name
      code
      createdAt
      updatedAt
      customers {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateDealer = /* GraphQL */ `
  mutation UpdateDealer(
    $input: UpdateDealerInput!
    $condition: ModelDealerConditionInput
  ) {
    updateDealer(input: $input, condition: $condition) {
      id
      name
      code
      createdAt
      updatedAt
      customers {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteDealer = /* GraphQL */ `
  mutation DeleteDealer(
    $input: DeleteDealerInput!
    $condition: ModelDealerConditionInput
  ) {
    deleteDealer(input: $input, condition: $condition) {
      id
      name
      code
      createdAt
      updatedAt
      customers {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createDealerCustomer = /* GraphQL */ `
  mutation CreateDealerCustomer(
    $input: CreateDealerCustomerInput!
    $condition: ModelDealerCustomerConditionInput
  ) {
    createDealerCustomer(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      customer {
        id
        first
        last
        crmName
        farmName
        email
        address
        city
        state
        zip
        phone
        growerId
        lat
        lon
        prospect
        dealerId
        dealerName
        createdAt
        updatedAt
        user {
          id
          email
          first
          last
          type
          phone
          dealerId
          createdAt
          updatedAt
        }
        dealers {
          nextToken
        }
        plans {
          nextToken
        }
      }
      dealer {
        id
        name
        code
        createdAt
        updatedAt
        customers {
          nextToken
        }
      }
    }
  }
`;
export const updateDealerCustomer = /* GraphQL */ `
  mutation UpdateDealerCustomer(
    $input: UpdateDealerCustomerInput!
    $condition: ModelDealerCustomerConditionInput
  ) {
    updateDealerCustomer(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      customer {
        id
        first
        last
        crmName
        farmName
        email
        address
        city
        state
        zip
        phone
        growerId
        lat
        lon
        prospect
        dealerId
        dealerName
        createdAt
        updatedAt
        user {
          id
          email
          first
          last
          type
          phone
          dealerId
          createdAt
          updatedAt
        }
        dealers {
          nextToken
        }
        plans {
          nextToken
        }
      }
      dealer {
        id
        name
        code
        createdAt
        updatedAt
        customers {
          nextToken
        }
      }
    }
  }
`;
export const deleteDealerCustomer = /* GraphQL */ `
  mutation DeleteDealerCustomer(
    $input: DeleteDealerCustomerInput!
    $condition: ModelDealerCustomerConditionInput
  ) {
    deleteDealerCustomer(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      customer {
        id
        first
        last
        crmName
        farmName
        email
        address
        city
        state
        zip
        phone
        growerId
        lat
        lon
        prospect
        dealerId
        dealerName
        createdAt
        updatedAt
        user {
          id
          email
          first
          last
          type
          phone
          dealerId
          createdAt
          updatedAt
        }
        dealers {
          nextToken
        }
        plans {
          nextToken
        }
      }
      dealer {
        id
        name
        code
        createdAt
        updatedAt
        customers {
          nextToken
        }
      }
    }
  }
`;
export const createPlan = /* GraphQL */ `
  mutation CreatePlan(
    $input: CreatePlanInput!
    $condition: ModelPlanConditionInput
  ) {
    createPlan(input: $input, condition: $condition) {
      id
      createdAt
      type
      name
      year
      defaultSettings
      cropValues
      dealer
      products {
        product
        brand
        crop
        trait
        acres
        maturity
        calcUnits
        orderUnits
      }
      updatedAt
      owner {
        id
        email
        first
        last
        type
        phone
        dealerId
        createdAt
        updatedAt
        customers {
          nextToken
        }
        plans {
          nextToken
        }
      }
      customer {
        id
        first
        last
        crmName
        farmName
        email
        address
        city
        state
        zip
        phone
        growerId
        lat
        lon
        prospect
        dealerId
        dealerName
        createdAt
        updatedAt
        user {
          id
          email
          first
          last
          type
          phone
          dealerId
          createdAt
          updatedAt
        }
        dealers {
          nextToken
        }
        plans {
          nextToken
        }
      }
      fields {
        items {
          id
          fieldId
          name
          number
          done
          acres
          tillage
          drainage
          irrigation
          yieldEnvironment
          idc
          gossWilt
          greensnap
          whiteMold
          phytophthora
          cyst
          trait
          product
          crop
          brand
          population
          rate
          variableRate
          zones
          rateRange
          rateSubmitted
          avgRate
          geoJson
          rowSpacing
          ph
          yieldTarget
          protein
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updatePlan = /* GraphQL */ `
  mutation UpdatePlan(
    $input: UpdatePlanInput!
    $condition: ModelPlanConditionInput
  ) {
    updatePlan(input: $input, condition: $condition) {
      id
      createdAt
      type
      name
      year
      defaultSettings
      cropValues
      dealer
      products {
        product
        brand
        crop
        trait
        acres
        maturity
        calcUnits
        orderUnits
      }
      updatedAt
      owner {
        id
        email
        first
        last
        type
        phone
        dealerId
        createdAt
        updatedAt
        customers {
          nextToken
        }
        plans {
          nextToken
        }
      }
      customer {
        id
        first
        last
        crmName
        farmName
        email
        address
        city
        state
        zip
        phone
        growerId
        lat
        lon
        prospect
        dealerId
        dealerName
        createdAt
        updatedAt
        user {
          id
          email
          first
          last
          type
          phone
          dealerId
          createdAt
          updatedAt
        }
        dealers {
          nextToken
        }
        plans {
          nextToken
        }
      }
      fields {
        items {
          id
          fieldId
          name
          number
          done
          acres
          tillage
          drainage
          irrigation
          yieldEnvironment
          idc
          gossWilt
          greensnap
          whiteMold
          phytophthora
          cyst
          trait
          product
          crop
          brand
          population
          rate
          variableRate
          zones
          rateRange
          rateSubmitted
          avgRate
          geoJson
          rowSpacing
          ph
          yieldTarget
          protein
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deletePlan = /* GraphQL */ `
  mutation DeletePlan(
    $input: DeletePlanInput!
    $condition: ModelPlanConditionInput
  ) {
    deletePlan(input: $input, condition: $condition) {
      id
      createdAt
      type
      name
      year
      defaultSettings
      cropValues
      dealer
      products {
        product
        brand
        crop
        trait
        acres
        maturity
        calcUnits
        orderUnits
      }
      updatedAt
      owner {
        id
        email
        first
        last
        type
        phone
        dealerId
        createdAt
        updatedAt
        customers {
          nextToken
        }
        plans {
          nextToken
        }
      }
      customer {
        id
        first
        last
        crmName
        farmName
        email
        address
        city
        state
        zip
        phone
        growerId
        lat
        lon
        prospect
        dealerId
        dealerName
        createdAt
        updatedAt
        user {
          id
          email
          first
          last
          type
          phone
          dealerId
          createdAt
          updatedAt
        }
        dealers {
          nextToken
        }
        plans {
          nextToken
        }
      }
      fields {
        items {
          id
          fieldId
          name
          number
          done
          acres
          tillage
          drainage
          irrigation
          yieldEnvironment
          idc
          gossWilt
          greensnap
          whiteMold
          phytophthora
          cyst
          trait
          product
          crop
          brand
          population
          rate
          variableRate
          zones
          rateRange
          rateSubmitted
          avgRate
          geoJson
          rowSpacing
          ph
          yieldTarget
          protein
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createField = /* GraphQL */ `
  mutation CreateField(
    $input: CreateFieldInput!
    $condition: ModelFieldConditionInput
  ) {
    createField(input: $input, condition: $condition) {
      id
      fieldId
      name
      number
      done
      acres
      tillage
      drainage
      irrigation
      yieldEnvironment
      idc
      gossWilt
      greensnap
      whiteMold
      phytophthora
      cyst
      trait
      product
      crop
      brand
      population
      rate
      variableRate
      zones
      rateRange
      rateSubmitted
      avgRate
      geoJson
      rowSpacing
      ph
      yieldTarget
      protein
      createdAt
      updatedAt
      plan {
        id
        createdAt
        type
        name
        year
        defaultSettings
        cropValues
        dealer
        products {
          product
          brand
          crop
          trait
          acres
          maturity
          calcUnits
          orderUnits
        }
        updatedAt
        owner {
          id
          email
          first
          last
          type
          phone
          dealerId
          createdAt
          updatedAt
        }
        customer {
          id
          first
          last
          crmName
          farmName
          email
          address
          city
          state
          zip
          phone
          growerId
          lat
          lon
          prospect
          dealerId
          dealerName
          createdAt
          updatedAt
        }
        fields {
          nextToken
        }
      }
    }
  }
`;
export const updateField = /* GraphQL */ `
  mutation UpdateField(
    $input: UpdateFieldInput!
    $condition: ModelFieldConditionInput
  ) {
    updateField(input: $input, condition: $condition) {
      id
      fieldId
      name
      number
      done
      acres
      tillage
      drainage
      irrigation
      yieldEnvironment
      idc
      gossWilt
      greensnap
      whiteMold
      phytophthora
      cyst
      trait
      product
      crop
      brand
      population
      rate
      variableRate
      zones
      rateRange
      rateSubmitted
      avgRate
      geoJson
      rowSpacing
      ph
      yieldTarget
      protein
      createdAt
      updatedAt
      plan {
        id
        createdAt
        type
        name
        year
        defaultSettings
        cropValues
        dealer
        products {
          product
          brand
          crop
          trait
          acres
          maturity
          calcUnits
          orderUnits
        }
        updatedAt
        owner {
          id
          email
          first
          last
          type
          phone
          dealerId
          createdAt
          updatedAt
        }
        customer {
          id
          first
          last
          crmName
          farmName
          email
          address
          city
          state
          zip
          phone
          growerId
          lat
          lon
          prospect
          dealerId
          dealerName
          createdAt
          updatedAt
        }
        fields {
          nextToken
        }
      }
    }
  }
`;
export const deleteField = /* GraphQL */ `
  mutation DeleteField(
    $input: DeleteFieldInput!
    $condition: ModelFieldConditionInput
  ) {
    deleteField(input: $input, condition: $condition) {
      id
      fieldId
      name
      number
      done
      acres
      tillage
      drainage
      irrigation
      yieldEnvironment
      idc
      gossWilt
      greensnap
      whiteMold
      phytophthora
      cyst
      trait
      product
      crop
      brand
      population
      rate
      variableRate
      zones
      rateRange
      rateSubmitted
      avgRate
      geoJson
      rowSpacing
      ph
      yieldTarget
      protein
      createdAt
      updatedAt
      plan {
        id
        createdAt
        type
        name
        year
        defaultSettings
        cropValues
        dealer
        products {
          product
          brand
          crop
          trait
          acres
          maturity
          calcUnits
          orderUnits
        }
        updatedAt
        owner {
          id
          email
          first
          last
          type
          phone
          dealerId
          createdAt
          updatedAt
        }
        customer {
          id
          first
          last
          crmName
          farmName
          email
          address
          city
          state
          zip
          phone
          growerId
          lat
          lon
          prospect
          dealerId
          dealerName
          createdAt
          updatedAt
        }
        fields {
          nextToken
        }
      }
    }
  }
`;
export const createProduct = /* GraphQL */ `
  mutation CreateProduct(
    $input: CreateProductInput!
    $condition: ModelProductConditionInput
  ) {
    createProduct(input: $input, condition: $condition) {
      id
      name
      trait
      maturity
      seedType
      brand
      archived
      createdAt
      updatedAt
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct(
    $input: UpdateProductInput!
    $condition: ModelProductConditionInput
  ) {
    updateProduct(input: $input, condition: $condition) {
      id
      name
      trait
      maturity
      seedType
      brand
      archived
      createdAt
      updatedAt
    }
  }
`;
export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct(
    $input: DeleteProductInput!
    $condition: ModelProductConditionInput
  ) {
    deleteProduct(input: $input, condition: $condition) {
      id
      name
      trait
      maturity
      seedType
      brand
      archived
      createdAt
      updatedAt
    }
  }
`;
