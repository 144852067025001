import {
    Auth,
    API,
    graphqlOperation
} from 'aws-amplify';
import {
    getDealer,
    listDealers,
    plansByDealer
} from '@/graphql/queries'
import axios from "axios";
import {
    createDealer
} from '@/graphql/mutations'

const state = {
    dealers: [],
    dealer: "",
    dealerPlans: []
}

const getters = {
}

const actions = {
    async getDealers({
        commit,
        dispatch,
    }) {
        try {
            const {
                data: {
                    listDealers: {
                        items
                    }
                }
            } = await API.graphql(graphqlOperation(listDealers, {
                limit: 1000
            }))
            commit("setDealers", items)
        } catch (error) {
            console.log(error)
        }
    },
    async getCRMDealers({ rootState, commit, dispatch }) {
        try {
            let { data } = await axios.get("https://pfseed.crm.dynamics.com/api/data/v9.1/pfs_dealers?$select=pfs_dealerid,pfs_name,pfs_businessphone,pfs_street1,pfs_city,pfs_state,pfs_zipcode,pfs_dealercode,_ownerid_value&$orderby=pfs_name asc&$filter=(statecode eq 0 and Microsoft.Dynamics.CRM.In(PropertyName='pfs_classification',PropertyValues=['229170000','229170002','229170001','229170003']))", {
                headers: {
                    Authorization: rootState.user.token
                }
            })
            commit("setDealers", data.value)
        } catch (error) {
            if (error.message === "Request failed with status code 401") {
                await dispatch("user/getClientToken", null, {
                    root: true
                })
            }
            else {
                console.log(error)
            }
        }
    },
    async getCRMDealer({ rootState, commit, dispatch }, dealerId) {
        try {
            let { data } = await axios.get(`https://pfseed.crm.dynamics.com/api/data/v9.1/pfs_dealers(${dealerId})?$select=pfs_dealerid,pfs_name,pfs_businessphone,pfs_street1,pfs_city,pfs_state,pfs_zipcode,pfs_dealercode`, {
                headers: {
                    Authorization: rootState.user.token
                }
            })
            return data
        } catch (error) {
            if (error.message === "Request failed with status code 401") {
                await dispatch("user/getClientToken", null, {
                    root: true
                })
            }
            else {
                console.log(error)
            }
        }
    },
    async getDealer({
        commit
    }, id) {
        try {
            const {
                data
            } = await API.graphql(graphqlOperation(getDealer, {
                id
            }))
            commit("setDealer", data.getDealer)
        } catch (error) {
            console.log(error)
        }
    },
    async plansByDealer({
        commit
    }, dealer) {
        try {
            const {
                data
            } = await API.graphql(graphqlOperation(plansByDealer, {
                dealer,
                limit: 1000
            }))
            commit("setDealerPlans", data.plansByDealer.items)
        } catch (error) {
            console.log(error)
        }
    },
    async createDealer({
        commit,
        dispatch,
    }, dealer) {
        try {
            const { data } = await API.graphql(graphqlOperation(createDealer, {
                input: {
                    name: dealer.name,
                    code: dealer.code
                }
            }))
            commit("addDealer", data.createDealer)
        } catch (error) {
            console.log(error)
        }
    }
}

const mutations = {
    setDealers(state, dealers) {
        state.dealers = dealers
    },
    setDealer(state, dealer) {
        state.dealer = dealer
    },
    addDealer(state, dealer) {
        state.dealers.push(dealer)
    },
    setDealerPlans(state, dealerPlans) {
        state.dealerPlans = dealerPlans
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}